$app-header-size: 56px;

:root {
  --app-side-nav-size: calc(59px + 2rem);
}

html {
  overflow: hidden;
}

.App {
  width: 100%;
  height: 100%;
}

.app-body {
  display: flex;
  padding-top: $app-header-size;
  height: 100%;

  & .motif-vertical-navigation.motif-vertical-navigation-expanded-width {
    width: calc(290px + 2rem) !important;
  }
}

.decarb-side-menu-hidden {
  --app-side-nav-size: calc(0px);
}

.decarb-side-menu-collapsed {
  --app-side-nav-size: calc(59px);
}

.decarb-side-menu-expanded {
  --app-side-nav-size: calc(290px + 2rem);
}

.app-body-content {
  padding-left: var(--app-side-nav-size);
  padding-bottom: 1.5rem;
  transition: padding-left 0.5s;
  width: 100%;
  height: 100%;
}

.required-star {
  color: #ff4136 !important;
  margin-left: 0.5rem;
}

.asd {
  margin-top: 6rem;
}

.main-div {
  height: 100%;
}

.motif-modal-overlay {
  background: rgba(26, 26, 36, 0.851);
}

.change-region-modal {
  overflow: visible !important;
  & .motif-modal-header {
    padding-bottom: 0;
    border-bottom: 0 !important;
  }

  .motif-modal-body {
    overflow: visible !important;
  }
}
.motif-tooltip-wrapper .motif-tooltip-trigger-wrap {
  align-content: center;
  flex-wrap: wrap;
}

.motif-tooltip-content {
  max-width: calc(100% - 4.2rem);
  padding-left: 4.2rem;
}
.motif-toast {
  display: block;
  position: fixed;
  top: 3rem;
  left: auto;
  width: 30rem;
  margin: 1rem;
}

.motif-toast-close-button {
  --toast-close-btn--bg-color: transparent;
  --toast-close-btn-hover--border-color: green;
  width: 2rem !important;
  position: relative;
  bottom: 1rem;
  right: 1rem;
}
