.file-version-history-modal {
  max-width: unset;

  & .motif-modal-header {
    padding-bottom: 0;
    border-bottom: 0;
  }
}

.file-version-history-modal-header {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;

  & .motif-fab {
    --btn-fab--bg-color: #2e2e39;
    cursor: auto;
  }

  & svg {
    border: 1px solid #2e2e39;
    background-color: #2e2e39;

    & path {
      // fill: unset !important;
      stroke: #a4a3b1 !important;
    }
  }
}

.file-version-history-modal-live-chip {
  .motif-chip-button {
    color: #48e674 !important;
    border-color: #153d25 !important;
  }
}

.donwload-archived-file-button {
  font-size: 14px;
  font-weight: 400;
  padding: 10px;

  & span {
    color: #85b9fd;
  }

  & svg {
    color: #85b9fd;

    & path {
      stroke: #85b9fd;
    }
  }
}
