.project-information {
  display: flex;
  flex-direction: column;
  background-color: #23232f;
  padding: 2rem;
  flex: 1;
  overflow: auto;
}

.project-information-header {
  border-bottom: 1px solid #3f3f4c;
  padding-bottom: 1rem;
}
.project-information-body-project-details-name,
.project-information-body-project-details-type {
  display: flex;
  flex-direction: row;
  column-gap: 0.3rem;
}
.project-information-header-main {
  font-size: 16px;
  font-weight: 400;
}

.project-information-body-project-details {
  display: flex;
  margin-top: 2rem;
  column-gap: 2rem;
  align-items: center;

  & .motif-input-component {
    flex: 1;
  }

  & > span {
    width: 12rem;
    color: #a4a3b1;
    font-weight: 400;
  }

  & .decarb-select {
    flex: 1;
  }
}

.project-information-body-project {
  margin-bottom: 2rem;
}

.project-information-body-project-desc {
  display: flex;
  margin-top: 2rem;
  column-gap: 2rem;
  align-items: center;

  & .motif-input-component {
    font-size: 16px;
  }

  .project-information-body-project-desc-name {
    padding-right: 1.35rem;
  }

  & span {
    display: flex;
    flex-direction: row;
    column-gap: 0.3rem;
    align-self: center;
    align-self: flex-start;
    color: #a4a3b1;
    font-weight: 400;
    .motif-tooltip-wrapper {
      padding-top: 0.3rem;
    }
  }

  .decarb-text-area {
    flex: 1;
  }
}

.project-information-body-project-tags {
  display: flex;
  margin-top: 2rem;
  column-gap: 2rem;
  align-items: center;

  & .motif-input-component {
    flex: 1;
  }

  & span {
    color: #a4a3b1;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    column-gap: 0.3rem;
    align-self: center;
    padding-right: 2.25rem;
    .motif-tooltip-wrapper {
      padding-top: 0.3rem;
    }
  }
}

.project-information-body-spec-header {
  border-top: 1px solid #3f3f4c;
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
}

.project-information-body-spec-header-main {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 1rem;
}

.project-information-body-spec-header-desc {
  font-size: 14px;
  font-weight: 400;
  color: #c4c4cd;
  font-style: italic;
}

.project-information-body-specification {
  display: flex;
  margin-top: 2rem;
  column-gap: 3rem;

  & .project-information-body-project-specification-dropdown {
    flex: 1;

    & span {
      color: #a4a3b1;
      font-weight: 400;
    }

    & .motif-select {
      margin-top: 0.5rem;
    }
  }
}
.multiselectDiv {
  position: relative;
  width: calc(100% - 14rem);
}
