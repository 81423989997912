.add-emission-modal-body-row {
  display: flex;
  padding-top: 2rem;
  .decarb-select {
    flex: 1;
    min-width: 20rem;
    max-width: 20rem;
    margin-right: 1rem;
  }
}

.add-emission-modal-body-row-title {
  width: 12rem;
  color: #a4a3b1;
  font-weight: 400;
  align-self: center;
}

.add-emission-modal-footer {
  display: flex;
  justify-content: flex-end;
  column-gap: 1rem;
}
