.decarb-button {
  font-size: 18px;
  font-weight: 400;
  padding: 10px 16px;

  & svg {
    color: #a4a3b1;

    & path {
      stroke: #a4a3b1;
    }
  }
}
