@use "@ey-xd/motif-core/src/styles/themes/utils/rem.function" as Rem;

.decarb-select {
  width: 100%;
  flex: 1;

  button {
    padding: 10px 16px;
    height: Rem.rem(48);
  }

  & .motif-select-input-text {
    font-weight: 300;
    font-size: 16px;
    color: #dedee2 !important;
  }

  & .motif-select-wrapper-options {
    transform: translate(0px, 52px);
    inset: 0px auto auto 0px;
  }

  .motif-select-input:disabled {
    border-color: #4e4e5d;
    border-style: solid;
    border-width: 1px;
  }
  .motif-select-clean-value {
    display: none !important;
  }
}
