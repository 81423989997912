.decarb-download-file-link {
  background-color: #2e2e38;
  padding: 10px 16px;
  border: 0;
  font-size: 16px !important;
  --text-link-hover--border-width: 0;
  color: var(--btn--color);

  & svg {
    color: #a4a3b1;

    & path {
      stroke: #a4a3b1;
    }
  }
}
