.new-engagement {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.new-engagement-header {
  display: flex;
  column-gap: 2rem;
  padding: 2rem;
  justify-content: space-between;
  border-bottom: 1px solid #3f3f4c;
}

.new-engagement-header-title {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
}

.new-engagement-header-stepper {
  width: 60%;
}

.new-engagement-content {
  display: flex;
  // margin: 2rem;
  padding: 2rem;
  flex: 1;
  overflow: auto;
}

.new-engagement-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 1.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  border-top: 1px solid #3f3f4c;
}

.new-engagement-footer-nav-buttons {
  display: flex;
  column-gap: 1rem;
}
