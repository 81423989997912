.analyse {
  flex: 1;
}

.analyse-header {
  display: flex;
  justify-content: space-between;
  flex: 1;
}

.analyse-header-description {
  font-size: 14px;
  font-weight: 400;
  color: #737387;
  margin-top: 1rem;
}

.analyse-header-title {
  font-size: 16px;
  font-weight: 400;
}

.analyse-header-h {
  display: flex;
  flex-direction: column;
}

.analyse-header-buttons {
  display: flex;
}
.analyse-header-new-project {
  font-size: 16px !important;
  font-weight: 400;
  margin-left: 10px;
}

.analyse-table-section {
  margin-top: 2rem;
  padding-bottom: 2rem;
}

.analyze-table-action {
  display: flex;
  gap: 1rem;

  & svg {
    color: #85b9fd;

    & path {
      fill: unset;
      stroke: #85b9fd;
    }
  }
}
.analyse-table-pending-validation {
  .ag-cell-value,
  .motif-chip-button {
    color: #ff4136 !important;
  }
}
.analyse-table-pending-validation-link {
  font-weight: 800;
  font-size: 14px;
  text-decoration: underline;
  color: #ff4136;

  &:hover {
    cursor: pointer;
  }
}
.analyse-table-data-validation-pending {
  opacity: 0.5;
}
.analyse-table-data-validation-link {
  text-decoration: none !important;
  cursor: unset !important;
  pointer-events: none;
}
.analyse-validation-message {
  padding: 1rem 0rem;
  color: #ff4136;
  font-weight: 400;
  font-family: EYInterstate;
}

.view-project-link {
  font-weight: 800;
  font-size: 14px;
  text-decoration: underline;
  color: #85b9fd;

  &:hover {
    cursor: pointer;
  }
}
.data-sync-message {
  margin-top: 1rem;
  font-weight: 400;
  font-style: EYInterstate;
  color: #85b9fd;
  text-align: center;
}

.motif-tooltip-wrapper .motif-tooltip-trigger-wrap {
  padding-right: 0.2rem !important;
}
