.session-timeout-modal {
  & .motif-modal-header {
    padding-bottom: 0;
    border-bottom: 0;
  }
  .motif-modal--header-icon-button {
    display: none !important;
  }
}

.session-timeout-modal-header {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;

  & svg {
    color: #a4a3b1 !important;
    padding: 8px;
    border: 1px solid #2e2e39;
    background-color: #2e2e39;
    border-radius: 32px;
    width: 42px;
    height: 42px;

    & path {
      fill: unset !important;
      stroke: #a4a3b1 !important;
    }
  }
}
