.empty-chart {
  height: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  & .MuiChartsGrid-line {
    stroke: #7f7f91 !important;
  }

  & .MuiChartsAxis-tickLabel {
    fill: #afaeba !important;

    & tspan {
      font-weight: 300;
      font-family: var(--primary-font);
      color: #afaeba;
    }
  }

  & .MuiChartsLegend-series {
    & text {
      fill: #9897a6 !important;
    }

    & tspan {
      font-weight: 300;
      font-family: var(--primary-font);
      font-size: 14px;
      color: #afaeba;
    }
  }

  & .MuiChartsAxis-label {
    & text {
      fill: #9897a6 !important;
    }

    & tspan {
      font-weight: 400;
      font-family: var(--primary-font);
      font-size: 12px;
      color: #afaeba;
    }
  }

  & .MuiChartsAxis-line {
    stroke: #7f7f91 !important;
  }

  & .MuiLineElement-root {
    stroke-width: 4px;
  }

  & .MuiChartsLegend-mark {
    clip-path: inset(0px round 10px 10px 10px 10px);
    y: -5;
    width: 10px;
    height: 10px;
  }
}
