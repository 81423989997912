.add-user-header {
  display: flex;
  flex: 1;
  flex-direction: column;
  row-gap: 2rem;
  .icon {
    background-color: #2e2e38;
    height: 48px;
    width: 48px;
    border-radius: 28px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  svg {
    height: 48px;
    width: 30px;
    align-self: center;
  }
}
.motif-modal-header {
  display: flex;
  // flex: 1;
}
.add-user-body {
  display: flex;
  flex: 1;
  flex-direction: column;
  row-gap: 2rem;

  .add-user-body-row {
    display: flex;
    flex-direction: row;
    column-gap: 3rem;
    .motif-select-option {
      span {
        width: fit-content;
      }
    }

    .motif-input-component {
      flex: 1;
    }

    .add-user-body-row-label {
      width: 6rem;
      align-self: center;
      color: #a4a3b1;
      font-family: EYInterstate;
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
      text-align: left;
      display: flex;
    }
    .motif-dropdown {
      display: flex;
      flex: 1;
      width: 100%;
    }
    .motif-dropdown .motif-dropdown-trigger {
      white-space: nowrap;
      cursor: pointer;
      position: relative;
      z-index: 1;
      display: flex;
      flex: 1;
    }

    .motif-dropdown-menu {
      width: 100%;
      height: fit-content;
      right: 0;
      background-color: #23232f;
    }

    .motif-dropdown-item {
      width: 100%;
    }
  }
}

.decarb-user-error {
  margin-left: 29%;
  margin-top: -25px;
}
