.analyse-heading {
  display: flex;
  flex-direction: column;
}

.analyse-heading-main {
  font-size: 18px;
  font-weight: 300;
}

.analyse-heading-desc {
  font-size: 16px;
  font-weight: 300;
  color: #afaeba;
}
