.decarb-loading-indicator-embed {
  position: relative !important;
  background-color: var(--body--bg-color);
  height: 100%;
  width: 100%;

  & .motif-progress-nested {
    height: 100%;
  }
}
