.home {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.home-top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
  background-image: url("../../assets/Images/HBG.webp");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  min-height: 400px;
  flex: 1;
}

.home-top-welcome-card {
  height: fit-content;
  opacity: 0.9;
  padding: 2rem;
  max-width: 460px;
  border-radius: 4px;
  width: fit-content;
}

.home-top-welcome-card-header {
  width: 100%;
  padding: 0;

  p {
    color: #dedee2;
  }
}

.home-top-welcome-card-header-title {
  font-size: 18px;
  color: white;
  font-weight: 700;
}

.home-top-welcome-card-body {
  padding: 0;
  margin-top: 1rem;

  p {
    color: #dedee2;
    font-size: 16px;
    line-height: 25px;
  }
}

.home-top-welcome-card-footer-buttons {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  margin-top: 3rem;
}

.home-top-welcome-card-footer-button {
  width: 100%;
}

.alt {
  border: 1px solid #4e4e5d;
}

.home-bottom {
  display: flex;
  flex-direction: column;
  padding: 2rem;
}

.home-bottom-header {
  font-size: 20px;
  color: white;
  font-weight: 400;
}

.home-bottom-cards {
  display: flex;
  flex-direction: row;
  column-gap: 2rem;
  margin-top: 2rem;
  justify-content: space-between;

  .motif-card-image > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.home-bottom-cards-card {
  flex: 1;
  height: 28rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 0.5rem;
}

.home-bottom-cards-card-header {
  display: flex;

  & span {
    color: #dedee2;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    width: 100%;
  }
}

.home-bottom-cards-card-body {
  display: flex;
  justify-content: center;
  overflow-y: unset;
  flex: 1;

  & span {
    color: #dedee2;
    font-size: 16px;
    text-align: center;
    align-self: center;
  }
}

@media screen and (max-width: 1680px) {
  .home-bottom-cards-card-image {
    height: 180px;
  }
}

@media screen and (min-width: 1681px) {
  .home-bottom-cards-card-image {
    height: 60%;
  }
}
