.emissions-cashflow-graph-content {
 .highcharts-legend-item {
  & text {
    fill: #DEDEE2 !important;
    font-size: 1.0em !important;
    font-weight: 100;
  }
 }
 .highcharts-legend-box{
  fill: transparent;
 }
 .highcharts-xaxis-labels{
  & text {
    fill: #afaeba !important;
    font-weight: 400;
    font-family: var(--primary-font);
  }
 }
 .highcharts-xaxis{
  & text {
    font-weight: 400;
    font-family: var(--primary-font);
    font-size: 12px !important;
    fill: #afaeba !important;
  }
 }
 .highcharts-credits{
  display: none;
 }
 .highcharts-exporting-group{
  display: none;
 }
}
