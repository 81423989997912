.change-fey-modal {
  & .motif-modal-header {
    padding-bottom: 0;
    border-bottom: 0;
  }
}

.file-version-history-modal-live-chip {
  .motif-chip-button {
    color: #48e674 !important;
    border-color: #153d25 !important;
  }
}
