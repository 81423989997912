.decarb-file-uploader {
  width: 100%;

  .motif-file-uploader-list-item-completed {
    display: none !important;
  }

  & .dropzone-container {
    height: 250px;
  }

  .motif-file-uploader-label-text {
    width: 210px;
    color: #a4a3b1;
    font-size: 14px;
    font-weight: 300;
    line-height: 25px;
  }

  .motif-file-uploader-label {
    row-gap: 1rem;
  }

  .motif-file-uploader-item {
    border: 1px solid #33333e;
    border-radius: 4px;

    & .motif-file-uploader-item-label {
      flex-direction: column !important;
      align-items: flex-start !important;

      & svg {
        width: 32px;
      }

      & span {
        margin-left: 4rem;
        margin-bottom: 4px;
        position: relative;
      }

      & .motif-file-uploader-item-file-name {
        top: -25px;
      }

      & .motif-file-uploader-item-file-size {
        top: -20px;
        font-weight: 300;
        font-size: 14px;
        color: #a4a3b1;
      }
    }

    & .motif-progress {
      background-color: #dedee2 !important;
      border-radius: 8px;
    }
  }

  & .motif-chip-button {
    padding: 1rem;
  }

  & .motif-file-uploader-item-uploaded {
    & .motif-chip-button {
      padding-bottom: 0px;
    }

    & .motif-file-uploader-item-custom-button-wrapper {
      position: relative;
      top: -5px;
    }
  }

  .decarb-file-uploader-item-hide-percentage {
    .motif-file-uploader-list-item-uploaded {
      display: none !important;
    }
  }
}

.decarb-file-uploader-item-buttons {
  display: flex;
  column-gap: 1rem;

  & .motif-fab {
    --btn-fab--bg-color: #2e2e39;
    cursor: auto;
  }

  & svg {
    & path {
      // fill: unset !important;
      stroke: #a4a3b1 !important;
    }
  }
}
