.content-body{
    font-size: 16px;
    font-weight: 300px;
    line-height: 24px;
};
.content-privacy{
    padding-top: 15px;
    font-size: 16px;
    font-weight: 300px;
    line-height: 24px;
};
.content-notice{
    color: #85B9FD;
}
.disclaimer-button{
    float: right;
}

.modal-head .motif-modal-header .motif-modal--header-icon-button {
    display: none;
}
.modal-head{
    width: 825px !important;
}