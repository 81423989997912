.emission-modal-body {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  border-top: 1px solid #33333e;
  padding-top: 2rem;
  .emission-modal-body-row {
    display: flex;
    flex-direction: row;
    column-gap: 3rem;
    span {
      width: 12rem;
      align-self: center;
      flex: 1;
    }
    .required-star {
      width: 1rem;
    }
    .decarb-select {
      flex: 1;
      min-width: 20rem;
      max-width: 20rem;
      margin-right: 1rem;
    }
  }
}
.emission-modal-footer {
  display: flex;
  flex-direction: row;
  column-gap: 2rem;
  justify-content: flex-end;
  align-items: flex-end;
  margin-right: 1rem;
  button {
    margin-top: 0;
    border: 1px solid #4e4e5d;
    align-items: flex-end;
  }
  .save {
    background: #2e2e38;
    margin-top: 0;
    height: 3rem;
    border: 0;
    font-family: EYInterstate;
    font-size: 16px;
    font-weight: 400;
  }
}
.motif-modal-footer {
  border: 0;
}
