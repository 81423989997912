.add-forecast-specification {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.add-forecast-specification-eng-header {
  display: flex;
  column-gap: 2rem;
  padding: 2rem;
  justify-content: space-between;
  border-bottom: 1px solid #3f3f4c;
}

.add-forecast-specification-eng-header-title {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
}

.add-forecast-specification-eng-header-stepper {
  width: 60%;
}

.add-forecast-specification-content {
  display: flex;
  padding: 2rem;
  flex: 1;
  flex-direction: column;
  overflow: auto;
}

.add-forecast-specification-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add-forecast-specification-header-main {
  font-size: 16px;
  color: #a4a3b1;
}

.add-forecast-specification-content-body-row {
  display: flex;
  column-gap: 2rem;
  padding-bottom: 1rem;
}

.add-forecast-specification-content-body-name-title {
  width: 12rem;
  color: #a4a3b1;
  font-weight: 400;
  align-self: center;
  display: flex;
  flex-direction: row;
  column-gap: 0.2rem;
}

.add-forecast-specification-content-body-desc-title {
  width: 12rem;
  color: #a4a3b1;
  font-weight: 400;
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  column-gap: 0.3rem;
}

.add-forecast-specification-body {
  margin-top: 2rem;
}

.add-forecast-specification-body-error-button {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.add-forecast-specification-body-forecast-desc {
  align-self: flex-start;
}

.add-forecast-specification-content-body-button {
  border: none;
  flex: 1;
  width: 100%;

  & svg {
    color: #fff;

    & path {
      stroke: #fff;
    }
  }

  p {
    display: inline-block;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-left: 1rem;
    padding: 0.1rem 0.5rem;
    color: #a4a3b1;
    background-color: #3f3f4c;
    font-size: 12px;
  }
}

.add-forecast-specification-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2rem 0 2rem;

  border-top: 1px solid #3f3f4c;
}
