.project-library-item {
  display: flex;
}

.project-library-item-body {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.project-library-item-header-h {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.project-library-item-header-tags {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;

  & .motif-chip-button {
    background-color: #064372;
    color: #afcdfb;
    border: 0;
    font-size: 14px;
    font-weight: 400;
  }
}

.project-library-item-body {
  & .motif-truncate-lines {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    max-width: 3000px;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.project-library-item-body-footer {
  display: flex;
  margin-top: 2rem;
  flex-direction: column;
  row-gap: 0.5rem;
}

.project-library-item-body-footer-row {
  flex: 1;
  display: flex;
  border-top: 1px solid #3f3f4c;
}

.project-library-item-body-footer-row-item {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.project-library-item-body-footer-row-item-right {
  padding-left: 1rem;
  border-left: 1px solid #3f3f4c;
}

.project-library-item-body-footer-row-item-header {
  color: #737387 !important;
  margin-top: 0.5rem;
}

.project-library-item-body-footer-row-item-value {
  color: #dedee2;
  font-weight: 400;
  font-size: 14px;
}
