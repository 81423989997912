.add-gmp {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.add-gmp-header {
  display: flex;
  column-gap: 2rem;
  padding: 2rem;
  justify-content: space-between;
  border-bottom: 1px solid #3f3f4c;
  border-top: 1px solid #3f3f4c;
}

.add-gmp-header-title {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
}

.add-gmp-header-stepper {
  width: 60%;
}

.add-gmp-content {
  display: flex;
  margin: 2rem;
  flex: 1;
  flex-direction: column;
  .add-gmp-table {
    display: flex;
    flex-direction: column;
    // row-gap: 1rem;
    flex: 1;
    .add-gmp-table-filter-section {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex: 1;
      padding-right: 20px;
      padding-top: 20px;
      button {
        background-color: #2e2e38;
        border: none;
        border-radius: 4px;
        width: fit-content;
        svg {
          padding-right: 0.35rem;
        }
      }
    }
    .add-gmp-table-filter-section-custom {
      display: flex;
      flex-direction: row;
      column-gap: 2rem;
      .motif-select {
        width: 20rem;
      }
    }
    .add-gmp-table-table-section {
      .motif-input-component .motif-input-clear-button {
        display: none;
      }
    }
  }
}

.add-gmp-content-header {
  display: flex;
  flex-direction: column;
}

.add-gmp-content-header-main {
  display: flex;
  align-items: center;
  column-gap: 1rem;

  & :not(:first-child) {
    color: #8a8a9b;
    font-size: 16px;
    font-weight: 400;
  }
}

.add-gmp-content-header-main-analyse {
  font-size: 16px;
  font-weight: 400;
}

.add-gmp-content-header-desc {
  font-size: 14px;
  font-weight: 400;
  color: #c4c4cd;
  font-style: italic;
  margin-top: 1rem;
}

.add-gmp-content-body {
  margin-top: 1rem;
  .motif-accordion-content-visible {
    background-color: transparent;
  }
}

.add-gmp-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  border-top: 1px solid #3f3f4c;
  background-color: #23232f;
  margin-bottom: 2rem;
}

.add-gmp-footer-nav-buttons {
  display: flex;
  column-gap: 1rem;
}
.add-gmp-params-title {
  display: flex;
  span {
    font-family: EYInterstate;
    font-size: 16px;
    // margin-left: 20px;
    line-height: 60px;
    align-self: center;
  }
  .motif-tooltip-wrapper .motif-tooltip-trigger-wrap {
    padding-right: 0 !important;
  }
}
// .add-gmp-hieraachry-container {
//   margin-top: 20px;
// }
.add-gmp-params-content {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding: 0.5rem 0rem;
  .add-gmp-params-content-row-dropdown {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    flex: 1;
    .tooltip {
      margin-left: 0.5rem;
      position: relative;
      top: 0.15rem;
    }
    .add-gmp-params-content-dropdowns-label {
      color: #dedee2;
      font-size: 14px;
    }
  }
}
.add-gmp-params-footer {
  display: flex;
  flex-direction: row;
  column-gap: 2rem;
  flex: 1;
  button {
    width: 50%;
    height: fit-content;
  }
}
.add-gmp-params-buttons {
  display: flex;
  // float: right;
  margin-top: 25px;
}
.add-gmp-params-buttons-div {
  margin-right: 25px;
}
// .add-gmp-params-button-save {
//   margin-left: 25px;
// }

.add-gmp-table-table-section {
  .motif-table {
    .ag-cell {
      & svg {
        margin-left: 0.6rem;
        // color: #fff;

        & path {
          stroke: #fff;
        }
      }
    }
    // .motif-icon-button path{
    //    fill: transparent
    // }
  }
}
.add-gmp-params-data-selection-btn{
  width: 100%;
  margin: 10px 0px 10px 0px;
}
.add-gmp-params-Edit-save {
  float: right;
  margin-bottom: 25px;
  margin-top: 10px;
}
.add-gmp-params-edit-reset{
  float: right;
  margin-bottom: 25px;
  margin-top: 10px;
  margin-right: 25px;
}

.editClass {
  svg path {
    fill: #fff !important;
  }
}
.nonEditClass {
  svg path {
    fill: transparent !important;
  }
}
.disableEditGMPIcon {
  cursor: not-allowed !important;
  pointer-events: unset !important;
}

.gmp-table-pending-validation {
  .ag-cell-value,
  .motif-chip-button {
    color: #ff4136 !important;
  }
}
.gmp-table-pending-validation-link {
  font-weight: 800;
  font-size: 14px;
  text-decoration: underline;
  color: #ff4136;

  &:hover {
    cursor: pointer;
  }
}
.emission-filter-modal-label,
.add-gmp-params-content-dropdowns-label {
  display: flex;
  .motif-tooltip-wrapper {
    padding-left: 2rem !important;
    align-self: center;
  }
}

.add-gmp-footer{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1.5rem 2rem;
  border-top: 1px solid #3f3f4c;
  background-color: #23232f;
  margin-bottom: 0;
}
.emission-gmp-modal {
  max-width: 85rem;
}