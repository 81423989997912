@use "@ey-xd/motif-core/src/styles/themes/utils/rem.function" as Rem;

$circle-size-sm: Rem.rem(24);
$circle-size-md: Rem.rem(32);
$circle-size-lg: Rem.rem(40);

.decarb-stepper {
  height: 100%;
  flex: 1;
  flex-wrap: nowrap;
  min-height: 2rem;
  align-items: center;

  & .motif-progress-indicator-step-status-title {
    font-size: 16px !important;
    background-color: var(--body--bg-color);
    padding-right: 10px;
    padding-left: 10px;
  }

  & .motif-progress-indicator-step-active {
    --progress-indicator-circle-active--bg-color: #ffe600;

    & .motif-progress-indicator-step-dot-icon {
      background-color: #2e2e38;
    }
  }

  & .motif-progress-indicator-step-disabled {
    --progress-indicator-circle-disabled--bg-color: #e6e6e9;
    --progress-indicator-circle-disabled--color: #c3c3cb;
  }

  & .motif-progress-indicator-step-status-description {
    display: none;
  }

  & .motif-progress-indicator-step-status-information {
    min-height: unset;
    padding: 0;
  }

  & .motif-progress-indicator-step {
    padding: 0;
    width: auto;
    flex-grow: unset;

    & .motif-progress-indicator-step-container {
      min-height: unset;
      flex-direction: row;
      gap: unset;

      & .motif-progress-indicator-step-status-situation {
        width: auto;
        padding-left: 10px;
        background-color: var(--body--bg-color);
      }

      & .motif-progress-indicator-step-status-bar {
        display: none;
      }
    }
  }

  & .motif-progress-indicator-step:not(:last-child) {
    & .motif-progress-indicator-step-status-title::after {
      content: "";
      display: block;
      height: 2px;
      background: white;
      top: 50%;
      position: absolute;
      z-index: -1;
      width: calc(100% + 3.5rem);
    }
  }

  & .motif-progress-indicator-step.motif-progress-indicator-step-pointer .motif-progress-indicator-step-container[role=button]:focus-within:not(.motif-progress-indicator-step-disabled){
    outline: none !important;
  }
  & .motif-progress-indicator-step.motif-progress-indicator-step-pointer .motif-progress-indicator-step-container[role=button]:hover:not(.motif-progress-indicator-step-disabled) {
    background-color: unset !important;
  }
}

.decarb-stepper-small {
  & .motif-progress-indicator-step-status-title {
    font-size: 12px !important;
  }

  & .motif-progress-indicator-step-status-icon {
    & svg {
      width: 24px;
      height: 24px;
    }
  }

  .motif-progress-indicator-step-container {
    gap: 0.5rem;
    padding: 0.5rem 0;
  }
}
