.financials-table {
  & .motif-checkbox-label-wrap {
    align-items: center;
  }

  & .ag-input-field-input {
    color: #dedee2 !important;
    border-radius: 4px !important;
    padding: 10px 14px !important;
    border-color: #4e4e5d !important;
    border-width: 1px !important;
    border-style: solid !important;
    font-size: 16px !important;
  }
}

.secondary-btn-apply {
  margin-top: 2.5rem;
  display: flex;
  flex-direction: row;
  column-gap: 0.3rem;

  & .decarb-loading-indicator-embed {
    width: fit-content;
  }
}
.financials-table {
  .motif-table {
    .ag-cell {
      & svg {
        margin-left: 0.5rem;
        color: #fff;

        & path {
          stroke: #fff;
        }
      }
    }
  }
  .financials-table-title {
    font-size: 16px;
    line-height: 60px;
  }
}
.gmp-link-table-pending-validation .ag-cell-value:first-of-type {
  color: #ff4136 !important;
}
