.projects-library {
  padding: 2rem 2rem 0 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.projects-library-header {
  display: flex;
  justify-content: space-between;
}

.projects-library-header-title {
  font-size: 28px;
  font-weight: 600;
  color: #dedee2;
}

.projects-library-header-h {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.projects-library-header-description {
  font-size: 14px;
  font-weight: 300;
  color: #dedee2;
}

.projects-library-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;

  & .decarb-search-input {
    width: 30rem;
  }

  & .projects-library-filter-selects {
    display: flex;
    align-items: center;
    column-gap: 1rem;

    & .decarb-select {
      width: 20rem;
    }
  }
}

.projects-library-content {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  margin-top: 2rem;
}

.projects-library-content-space-between {
  justify-content: space-between;
}

.projects-library-content-normal {
  justify-content: normal;
}

.projects-library-content-center {
  justify-content: center;
  align-items: center;
}

.projects-library-projects-list {
  display: flex;
  margin-top: 2rem;
  flex-wrap: wrap;
  row-gap: 1rem;
  column-gap: 1rem;
  overflow: auto;
  height: 100%;

  & .motif-card-orientation-vertical {
    height: 30rem;
    width: 32%;
  }

  & .motif-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 32%;
  }

  & .motif-card-header {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    align-items: unset;

    & .motif-h6 {
      font-size: 16px;
      color: #bfbfc8;
    }

    & p {
      color: #737387;
    }

    & span {
      font-size: 20px;
      font-weight: 600;
      color: #f3f3f5;
    }

    & button {
      background-color: #2e2e38;
      padding: 12px;
      border: 0;
      border-radius: 4px;

      & svg {
        color: #a4a3b1;

        & path {
          stroke: #a4a3b1;
        }
      }
    }
  }

  & .motif-card-body {
    & span {
      color: #f3f3f5;
    }
  }

  .summary-percent {
    font-size: 34px;
    font-weight: 600;
    color: white !important;
  }

  .forecast-summary-loading {
    height: 250px;
  }
}

.projects-library-no-projects {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.projects-library-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 2rem;
}
