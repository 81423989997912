.simulate-heading {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}

.simulate-heading-main {
  font-size: 18px;
  font-weight: 400;
  color: #dedee2;
}

.simulate-heading-desc {
  font-size: 16px;
  font-weight: 300;
  color: #737387;
}
