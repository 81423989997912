@import "../../App.scss";

.decarb-header {
  height: $app-header-size;
  position: fixed;
  width: 100%;
  top: 0;
  background-color: #1a1a24;

  & .motif-header-app-name {
    margin-bottom: 0 !important;
  }

  & .motif-header-vertical-navigation-open-button {
    & path {
      fill: #a4a3b1 !important;
    }
  }

  .motif-dropdown {
    display: flex;
    flex: 1;
    width: 100%;
  }

  .motif-dropdown-menu {
    height: fit-content;
    right: 0;
    background-color: Var(--body--bg-color);
    transform: none !important;
    inset: auto 0 auto auto !important;
    margin-top: 0.5rem;
    top: 100% !important;
  }

  .motif-dropdown-item {
    width: 100%;

    .motif-avatar-size-large {
      height: 5rem;
      width: 5rem;
    }
  }
}

.decarb-header-logo {
  &:hover {
    cursor: pointer;
  }
}

.decarb-header-left-components {
  & .motif-icon-button {
    align-items: center !important;
  }

  & .motif-icon {
    margin: 0 !important;
  }
}

.decarb-header-right-components {
  display: flex;
  align-items: center;
  column-gap: 2rem;

  & svg {
    height: 1.5rem;

    path {
      fill: #a4a3b1 !important;
    }
  }
}

.decarb-header-right-components-user-menu {
  position: absolute;
  right: 0;
  top: $app-header-size;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  row-gap: 1rem;
}

.decarb-header-right-components-user-menu-content {
  flex: 1;
  display: flex;
  align-items: center;
  column-gap: 1rem;

  & .motif-avatar-size-large {
    height: 7rem;
    width: 7rem;
  }
}

.decarb-header-right-components-user-menu-content-user-info {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;

  & .user-info-name {
    font-size: 16px;
    font-weight: 400;
  }
}

.decarb-header-right-components-user-menu-sign-out {
  flex: 1;
  background-color: #2e2e38;
  align-self: flex-end;
}

.tooling-body {
  padding-top: 4px;
}
