.setup {
  flex: 1;
}

.setup-header {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.setup-header-main {
  font-size: 16px;
  font-weight: 300;
  color: #dedee2;
}

.setup-body {
  padding-bottom: 2rem;
}

.setup-body-row {
  display: flex;
  margin-top: 2rem;
  column-gap: 2rem;
  align-items: center;
  width: 100%;

  .setup-user {
    width: 12rem;
    font-family: EYInterstate;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    text-align: left;
    color: #a4a3b1;
  }

  & .setup-row-half {
    display: flex;
    column-gap: 1rem;
    width: 50%;

    > span {
      width: 12rem;
      color: #a4a3b1;
      align-self: center;
      display: flex;
    }
  }
  .decarb-select {
    width: 20rem;
    span {
      padding-left: none;
    }
  }
}
.setup-team {
  width: 7rem;
}

.setup-body-details {
  border-bottom: 1px solid #3f3f4c;
  padding-bottom: 2rem;
}

.setup-body-people {
  margin-top: 2rem;
  .motif-input-component {
    padding-left: 2rem;
  }
}

.setup-body-people-table {
  margin-top: 2rem;

  & svg {
    color: #85b9fd;

    & path {
      fill: unset;
      stroke: #85b9fd;
    }
  }
}
