.project-details {
  padding: 2rem 2rem 0 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.project-details-header {
  display: flex;

  & button {
    background-color: #2e2e38;
    padding: 10px 16px;
    border: 0;
    font-size: 16px !important;

    & svg {
      color: #a4a3b1;

      & path {
        stroke: #a4a3b1;
      }
    }
  }
}

.project-details-content {
  overflow: auto;
  padding-bottom: 2rem;
  flex: 1;
}

.project-details-name {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.project-details-name-n {
  display: flex;
  column-gap: 1rem;

  & span {
    color: #dedee2;
    font-size: 18px;
    font-weight: 400;
  }

  & .motif-chip-button {
    color: #dedee2;
    font-size: 14px;
    font-weight: 400;
    border-color: #33333e !important;
  }
}

.project-details-name-type {
  display: flex;
  column-gap: 1rem;
  align-items: center;

  & span {
    color: #a4a3b1;
  }

  & .project-details-type {
    & .motif-chip-button {
      color: #60f6f3;
      font-size: 14px;
      font-weight: 400;
      border-color: #0b4847 !important;
      background-color: #0b4847;
    }
  }

  & .project-details-tags {
    & .motif-chip-button {
      color: #afcdfb;
      font-size: 14px;
      font-weight: 400;
      border-color: #064372 !important;
      background-color: #064372;
    }
  }
}

.project-details-desc {
  margin-top: 2rem;

  & span {
    color: #dedee2;
  }
}

.project-details-bcs {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;

  & > span {
    color: #dedee2;
    font-size: 16px;
  }
}

.project-details-bcs-tiles {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  gap: 1rem;

  & .motif-card-orientation-horizontal {
    height: 12rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  & .motif-card {
    padding: 1rem;
    width: 15%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  & .motif-card-body span {
    font-weight: 600;
    font-size: 28px;
  }

  & .project-details-bcs-tiles-header {
    font-size: 16px;
    color: #737387;
  }
}

.empty-card {
  opacity: 0;
}

.project-details-table {
  margin-top: 2rem;
}
