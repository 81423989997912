.forecast-import-excel {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.forecast-import-excel-eng-header {
  display: flex;
  column-gap: 2rem;
  padding: 2rem;
  justify-content: space-between;
  border-bottom: 1px solid #3f3f4c;
}

.forecast-import-excel-eng-header-title {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
}

.forecast-import-excel-eng-header-stepper {
  width: 60%;
}

.forecast-import-excel-content {
  display: flex;
  padding: 2rem;
  flex: 1;
  flex-direction: column;
  overflow: auto;
}

.forecast-import-excel-header {
  display: flex;
  flex-direction: column;
}

.forecast-import-excel-header-main {
  font-size: 18px;
  font-weight: 400;
  color: #dedee2;
}

.forecast-import-excel-header-desc {
  font-size: 14px;
  font-weight: 300;
  color: #737387;
  margin-top: 1rem;
}

.forecast-import-excel-body {
  margin-top: 2rem;
}

.forecast-import-excel-body-template {
  display: flex;
  align-items: center;
  column-gap: 2rem;
  padding-bottom: 2rem;

  border-bottom: 1px solid #3f3f4c;
}

.forecast-import-excel-body-template-title {
  color: #a4a3b1;
  width: 12rem;
  display: flex;
  flex-direction: row;
  column-gap: 0.3rem;
}

.forecast-import-excel-body-upload {
  display: flex;
  flex-direction: column;
  column-gap: 2rem;
  padding-bottom: 2rem;

  & .forecast-import-excel-body-upload-file {
    flex: 1;
  }
}

.forecast-import-excel-body-upload-year {
  display: flex;
  margin-top: 2rem;
  column-gap: 2rem;
  align-items: center;

  & .motif-input-component {
    width: 17rem;
  }

  & span {
    width: 12rem;
    color: #a4a3b1;
  }
}

.forecast-import-excel-body-upload-section {
  display: flex;
  column-gap: 2rem;
  align-items: center;
  margin-top: 2rem;
}

.forecast-import-excel-body-upload-title {
  width: 12rem;
  color: #a4a3b1;
  align-self: flex-start;
}

.forecast-import-excel-body-upload-uploader-manage-file-buttons {
  display: flex;
  margin-top: 2rem;
  column-gap: 2rem;

  & button {
    background-color: #2e2e38;
    padding: 10px 16px;
    border: 0;
    font-size: 16px !important;
  }
}

.forecast-import-excel-body-upload-year {
  display: flex;
  column-gap: 2rem;
  margin-top: 2rem;
}

.forecast-import-excel-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2rem 0 2rem;

  border-top: 1px solid #3f3f4c;

  & .decarb-loading-indicator-embed {
    width: fit-content;
  }
}

.forecast-import-excel-body-upload-year-label-l {
  flex: 1;
  color: #dedee2 !important;
  font-size: 16px;
}
