.forecast-modal-body {
  font-size: 16px;
  color: #dedee2;
}

.forecast-modal-footer {
  display: flex;
  justify-content: flex-end;
  column-gap: 1rem;
}
