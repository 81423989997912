.simulate {
  height: 100%;
  width: 100%;
}

.simulate-header {
  display: flex;
  justify-content: space-between;
  flex: 1;
}

.simulate-header-main {
  font-size: 18px;
  font-weight: 400;
  color: #dedee2;
}

.simulate-header-desc {
  font-size: 14px;
  font-weight: 300;
  color: #737387;
  margin-top: 1rem;
}

.simulate-header-h {
  display: flex;
  flex-direction: column;
}

.simulate-header-buttons {
  display: flex;
}

.simulate-header-sync-scenario {
  font-size: 16px !important;
  font-weight: 400;
}

.simulate-header-new-scenario {
  font-size: 16px !important;
  font-weight: 400;
  margin-left: 10px;
}

.simulate-table {
  margin-top: 2rem;
  padding-bottom: 2rem;

  // .motif-tooltip-wrapper {
  //   display: flex;
  // }

  .motif-progress-indicator-heading {
    background-color: #23232f;
  }
}
.scenario-table-pending-validation {
  .ag-cell-value,
  .motif-chip-button {
    color: #ff4136 !important;
  }
}
.scenario-table-data-sync-pending {
  opacity: 0.5;
  cursor: unset;
  pointer-events: none;
}
.scenario-table-data-validation-link {
  text-decoration: none !important;
  cursor: unset !important;
  pointer-events: none !important;
}
.scenario-table-pending-validation-link {
  font-weight: 800;
  font-size: 14px;
  text-decoration: underline;
  color: #ff4136;

  &:hover {
    cursor: pointer;
  }
}
.scenario-link {
  font-weight: 800;
  font-size: 14px;
  text-decoration: underline;
  color: #85b9fd;
  cursor: pointer !important;
  pointer-events: unset !important;
  
  &:hover {
    cursor: pointer;
  }
}
