.add-target {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.add-target-eng-header {
  display: flex;
  column-gap: 2rem;
  padding: 2rem;
  justify-content: space-between;
  border-bottom: 1px solid #3f3f4c;
}

.add-target-eng-header-title {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
}

.add-target-eng-header-stepper {
  width: 60%;
}

.add-target-content {
  display: flex;
  padding: 2rem;
  flex: 1;
  flex-direction: column;
  height: fit-content;
  overflow-y: auto;
}

.add-target-content-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
}

.add-target-header-button {
  display: flex;
  column-gap: 2rem;

  & button {
    font-size: 16px !important;
  }
}

.add-target-header-main {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  font-size: 16px;
  font-weight: 400;
}

.add-target-body-table {
  margin-top: 2rem;

  .motif-table .ag-cell {
    & svg {
      color: #85b9fd;

      & path {
        fill: unset;
        stroke: #85b9fd;
      }
    }
  }
}

.add-target-table-pending-validation {
  & .ag-cell-value,
  .motif-chip-button {
    color: #ff4136 !important;
  }
}

.add-target-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2rem 0 2rem;

  border-top: 1px solid #3f3f4c;
}
.add-target-footer-nav-buttons {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  button {
    width: 4rem;
    align-self: flex-end;
  }
}
