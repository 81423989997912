.project-results {
  display: flex;
  flex-direction: column;
  background-color: #23232f;
  padding: 2rem;
  overflow: auto;
}

.project-results-header {
  border-bottom: 1px solid #3f3f4c;
  padding-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & button {
    font-size: 16px !important;
    font-weight: 400;
  }
}

.project-results-header-main {
  font-size: 16px;
  font-weight: 300;
  color: #a4a3b1;
}

.project-results-body {
  margin-top: 2rem;
}

.project-results-body-emissions-chart-header {
  color: #dedee2;
  font-size: 16px;
  font-weight: 400;
  margin-top: 2rem;
}

.project-results-body-emissions-chart {
  margin-top: 3rem;
}

.project-results-body-business-summary {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  row-gap: 1.5rem;

  & .motif-card-orientation-horizontal {
    height: 15rem;
    width: 15%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 12px;
  }

  & .motif-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 10%;
  }

  & .motif-card-header {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    align-items: flex-start;

    & .motif-h6 {
      font-size: 16px;
      color: #afaeba !important;
      font-weight: 100;
    }

    & p {
      color: #737387;
    }
  }

  & .motif-card-body {
    overflow-y: hidden;
    text-align: center;
    & p {
      color: #737387;
      margin: 10px 0;
    }
  }

  .summary-percent {
    font-size: 25px;
    font-weight: 600;
    color: white !important;
  }

  .forecast-summary-loading {
    height: 250px;
  }
}
