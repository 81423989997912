.manage-emissions {
  flex: 1;
}

.manage-emissions-header {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.manage-emissions-header-main {
  font-size: 18px;
  font-weight: 400;
  color: #dedee2;
}

.manage-emissions-header-desc {
  font-size: 14px;
  font-weight: 300;
  color: #737387;
  margin-top: 1rem;
}

.manage-emissions-body {
  margin-top: 2rem;
}

.manage-emissions-body-upload-year {
  display: flex;
  margin-top: 2rem;
  column-gap: 2rem;
  align-items: center;

  & .motif-input-component {
    width: 17rem;
  }

  & > span {
    width: 12rem;
    color: #a4a3b1;
  }
}

.manage-emissions-body-template {
  display: flex;
  align-items: center;
  column-gap: 2rem;
  padding-bottom: 2rem;
  margin-top: 2rem;

  border-bottom: 1px solid #3f3f4c;
}

.manage-emissions-body-template-title {
  color: #a4a3b1;
  width: 12rem;
  display: flex;
  flex-direction: row;
  column-gap: 0.2rem;
  align-self: center;
}

.manage-emissions-body-upload {
  display: flex;
  flex-direction: column;
  column-gap: 2rem;
  padding-bottom: 2rem;

  & .manage-emissions-body-upload-file {
    flex: 1;
  }
}

.template-upload-error {
  color: goldenrod;
  font-size: 14px;
  font-weight: 400;
  margin-top: 1rem;
}
.template-upload-success {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  margin-top: 1rem;
}

.manage-emissions-body-upload-title {
  width: 12rem;
  color: #a4a3b1;
  align-self: flex-start;
}

.manage-emissions-body-upload-section {
  display: flex;
  column-gap: 2rem;
  align-items: center;
  margin-top: 2rem;
}
.manage-emissions-body-upload-year-title {
  display: flex;
  flex-direction: row;
  column-gap: 0.2rem;
  align-self: center;
}
.manage-emissions-body-upload-uploader-manage-file-buttons {
  display: flex;
  margin-top: 2rem;
  column-gap: 2rem;

  & button {
    font-size: 16px !important;
  }
}

.button-progress {
  background-color: rgba(242, 242, 246, 0.3019607843);
}

.show-file-upload-status {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;

  & span {
    font-weight: 400;
    color: #a4a3b1;
    flex: 0.6;
  }

  & .motif-progress-loader {
    flex: 2;
  }
}

.hide-file-upload-status {
  display: none;
}

.excel-upload-data-verification-error {
  flex-direction: row !important;
  align-items: center;
  column-gap: 2rem;

  & span {
    color: goldenrod;
    font-size: 14px;
    font-weight: 400;
    margin-top: 1rem;
  }

  & a {
    height: fit-content;
  }
}

.manage-emissions-body-upload-file-history-button {
  padding: 10px 16px;
  border: 0;
  font-size: 16px !important;
  color: #85b9fd !important;

  & svg {
    color: #85b9fd;

    & path {
      stroke: #85b9fd;
    }
  }
}

.manage-emissions-body-upload-file-history-button:hover {
  color: #85b9fd !important;
}

.MuiTooltip-tooltip {
  padding: 6px 10px !important;
}

.forecast-download-error-report-button {
  background-color: #2e2e38;
  padding: 10px 16px;
  border: 0;
  font-size: 16px !important;

  & svg {
    color: #a4a3b1;

    & path {
      stroke: #a4a3b1;
    }
  }
}
