.emissions-mac-curve-content {
  .highcharts-legend-item {
    & text {
      fill: #dedee2 !important;
      font-size: 1em !important;
      font-weight: 100;
    }
  }
  .highcharts-legend-box {
    fill: transparent;
  }
  .highcharts-xaxis-labels {
    & text {
      fill: #afaeba !important;
      font-weight: 400;
      font-family: var(--primary-font);
    }
  }
  .highcharts-xaxis {
    & text {
      font-weight: 400;
      font-family: var(--primary-font);
      font-size: 12px !important;
      fill: #afaeba !important;
    }
  }
  .highcharts-credits {
    display: none;
  }
  .highcharts-text-outline{
    stroke-width: 0 !important;
  }
  .highcharts-data-label text{
        font-size: 0.7em;
        font-weight: unset !important;
        fill: #fff !important;
    }
    tspan {
      stroke: unset;
    stroke-width: unset;
    }
    .highcharts-exporting-group{
      display: none !important;
    }
}
