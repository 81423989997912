.remove-user-header {
  display: flex;
  flex: 1;
  flex-direction: column;
  row-gap: 2rem;
  .icon {
    background-color: #2e2e38;
    height: 48px;
    width: 48px;
    border-radius: 28px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  svg {
    //   height: 48px;
    //   width: 30px;
    align-self: center;
  }
}
.body-content {
  font-family: EYInterstate;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
  color: #dedee2;
}

.remove-user-footer {
  display: flex;
  flex-direction: row;
  column-gap: 2rem;
  justify-content: flex-end;
  button {
    margin-top: 0 !important;
  }
}
