.financials {
  display: flex;
  flex-direction: column;
  background-color: #23232f;
  padding: 2rem;
  overflow: auto;
  flex: 1;
}

.financials-header {
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

.financials-header-main {
  font-size: 16px;
  font-weight: 400;
}
.gmp-button-link {
  padding: 10px 16px;
  border: 0;
  font-size: 16px !important;
  color: #85b9fd !important;

  & svg {
    margin-right: 0.5rem;
    color: #85b9fd;

    & path {
      stroke: #85b9fd;
    }
  }
}

.financials-body {
  display: flex;
  flex-direction: row;
  column-gap: 2rem;
}
.new-label {
  padding-top: 0rem;
  font-size: 10px;
}

.financial-unit-validation-modal{
.ag-paging-panel {
  display: none;
}
}
.emissions-change-log-content-msg {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}