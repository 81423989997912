.forecast {
  flex: 1;
}

.forecast-header {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-bottom: 1rem;
  column-gap: 2rem;

  border-bottom: 1px solid #3f3f4c;
}

.forecast-header-a {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.forecast-header-aa {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.forecast-header-main {
  font-size: 18px;
  font-weight: 400;
  color: #dedee2;
}

.forecast-header-desc {
  font-size: 14px;
  font-weight: 300;
  color: #737387;
  margin-top: 1rem;
}

.forecast-body {
  margin-top: 2rem;
}

.forecast-body-results {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.forecast-body-results-title {
  font-size: 16px;
  font-weight: 400;
}

.forecast-body-results-powewrbi {
  display: flex;
  column-gap: 2rem;
  align-items: center;

  & .decarb-select {
    width: 15rem;
  }

  & button {
    padding: 10px 16px;
    font-size: 16px !important;
    font-weight: 400;
  }
}

.forecast-body-results-analytics {
  display: flex;
  column-gap: 2rem;

  & button {
    font-size: 16px !important;
    font-weight: 400;

    p {
      display: inline-block;
      margin-block-start: 0;
      margin-block-end: 0;
      margin-left: 1rem;
      padding: 0.1rem 0.5rem;
      color: #a4a3b1;
      background: #3f3f4c;
      font-size: 12px;
    }
  }
  .button-color-yellow {
    color: #ff9831;
  }
}

.forecast-body-chart-section {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  padding-bottom: 2rem;
}

.forecast-body-chart-section-title {
  font-size: 16px;
  font-weight: 400;
  color: #dedee2;
}

.forecast-body-chart-section-chart {
  margin-top: 2rem;
}

.forecast-body-chart-section-a {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.forecast-body-chart-section-summary {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  gap: 2rem;

  & .motif-card-orientation-vertical {
    height: 18rem;
    width: 30%;
  }

  & .motif-card-header {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    align-items: flex-start;

    & .motif-h6 {
      font-size: 16px;
      color: #bfbfc8;
      display: flex;
      flex-direction: row;
      column-gap: 0.4rem;
    }

    & span {
      color: #737387;
    }
  }

  & .motif-card-body {
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: flex-end;

    & span {
      color: #737387;
    }
  }

  .summary-percent {
    font-size: 34px;
    font-weight: 600;
    color: white !important;
  }

  .forecast-summary-loading {
    height: 250px;
  }
}

.forecast-body-upload-year {
  display: flex;
  column-gap: 2rem;
  align-items: center;
  margin-top: 2rem;

  & > span {
    width: 12rem;
    color: #a4a3b1;
    font-weight: 400;
    display: flex;
  }
}

.forecast-body-upload-year-edit-title {
  font-size: 14px;
  align-self: end;
  span {
    padding-left: 0.3rem;
  }
}

.forecast-body-upload-year-save-title {
  font-size: 14px;
  span {
    padding-left: 0.3rem;
  }
}

.forecast-body-upload-year-save-button {
  background-color: #2e2e38;
  padding: 10px 0;
  border: 0;
  font-size: 16px !important;
  font-weight: 400;
  min-width: 4rem;
  align-self: start;

  & svg {
    color: #a4a3b1;

    & path {
      stroke: #a4a3b1;
    }
  }
}

.forecast-body-upload-year-edit-button {
  background-color: #2e2e38;
  padding: 10px 0;
  border: 0;
  font-size: 16px !important;
  font-weight: 400;
  min-width: 4rem;
  align-self: start;

  & svg {
    color: #a4a3b1;

    & path {
      stroke: #a4a3b1;
    }
  }
}

.forecast-body-upload-fey-input {
  width: 30rem !important;
}
