.remove-record-header {
  display: flex;
  flex: 1;
  flex-direction: column;
  row-gap: 2rem;
  .icon {
    background-color: #2e2e38;
    height: 48px;
    width: 48px;
    border-radius: 28px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  svg {
    align-self: center;
  }
}
.custom-delete-footer {
  display: flex;
  flex: 1;
  flex-direction: row;
  column-gap: 1rem;
  justify-content: flex-end;
  button {
    margin-top: 0px !important;
  }
}

.modalbody {
  max-width: 52.571429rem !important;
}

.add-forecast-delete-modal {
  .motif-modal-header {
    border-bottom-width: 0px;
  }
}
