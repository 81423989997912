.delete-forecast-icon {
  background-color: transparent;
  svg {
    stroke: #85b9fd !important;
  }
}
.delete-forecast-icon :hover {
  background-color: #2e2e38;
}
.delete-forecast-icon-inactive {
  svg {
    stroke: #85b9fd !important;
  }
}

.add-forecast-table-custom-edit {
  display: flex;
  column-gap: 1.5rem;
  justify-content: center;
  width: 100%;

  & svg {
    color: #85b9fd;

    & path {
      fill: unset;
      stroke: #85b9fd;
    }
  }
}
