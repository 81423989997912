.add-forecast-modal-header {
  display: flex;
  flex: 1;
  flex-direction: column;
  row-gap: 1rem;
  padding: 1rem 0rem 0rem 1rem;
  .icon {
    background-color: #2e2e38;
    height: 48px;
    width: 48px;
    border-radius: 28px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  svg {
    height: 48px;
    width: 30px;
    align-self: center;
  }
  span {
    color: #dedee2;
    font-family: EYInterstate;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    text-align: left;
  }
  .heading {
    font-size: 18px;
  }
}
.add-forecast-modal-body {
  display: flex;
  flex: 1;
  flex-direction: row;
  column-gap: 3rem;
  width: 100%;

  .add-forecast-modal-body-row-label {
    width: 12rem;
    align-self: center;
    color: #a4a3b1;
    font-family: EYInterstate;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    text-align: left;
    align-self: center;
  }
  .add-forecast-modal-input {
    // width: 21.5rem;
  }
}

.add-forecast-modal-footer {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  justify-content: flex-end;
  button {
    margin-top: 0 !important;
  }
}
