.add-custom-modal-body-row {
  display: flex;
  column-gap: 2rem;
  margin-bottom: 1rem;

  .decarb-select {
    flex: 1;
    max-width: 21rem;
  }
}

.add-custom-modal-body-row-title {
  width: 12rem;
  color: #a4a3b1;
  font-weight: 400;
  align-self: center;
}

.add-custom-modal-footer {
  display: flex;
  justify-content: flex-end;
  column-gap: 1rem;
}
