.decarb-input {
  --input--color: #dedee2;
  padding: 10px 14px !important;
  border-color: #4e4e5d !important;
  border-width: 1px !important;
  border-style: solid !important;
  font-size: 16px !important;
  color: #dedee2;
}

.decarb-input-hide-error {
  visibility: hidden;
}

.decarb-input-error-label {
  position: relative;
  top: -12px;
}

.decarb-input-error-message {
  & span {
    width: fit-content !important;
  }
}

.decarb-input::-webkit-outer-spin-button,
.decarb-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: none;
}
