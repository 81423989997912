.decarb-multiselect {
  border-radius: 4px !important;
  border-color: #4e4e5d !important;
  border-width: 1px !important;
  border-style: solid !important;
  font-size: 16px !important;
  color: #dedee2 !important;
}

.decarb-multiselect > .dropdown-container{
  background-color: #23232f !important;
  border: none !important;
  box-shadow: none !important;
}
.decarb-multiselect .panel-content{
  background-color: #23232f !important;
}

.decarb-multiselect .dropdown-content{
z-index: 10;
}
.decarb-multiselect .select-item:hover, .decarb-multiselect .select-item.selected{
background-color: #2e2e3c !important;
}

.decarb-multiselect .search input:focus{
  background-color: #2e2e3c !important;
  color: #dedee2 !important;
}

.decarb-multiselect input[type=checkbox]{
  accent-color:  #dedee2 !important;  
  }
// #1a1a24

.decarb-multiselect .options li {
  color: #85B9FD;
}