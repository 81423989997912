.decarb-text-area {
  width: 100%;

  & textarea {
    border-radius: 4px !important;
    padding: 10px 14px !important;
    border-color: #4e4e5d !important;
    border-width: 1px !important;
    border-style: solid !important;
    width: 100% !important;
    font-size: 16px !important;
  }
}

.decarb-text-area-hide-label {
  display: none !important;
}

.decarb-text-area-show-label {
  display: block !important;
}

.decarb-text-area-label {
  font-size: 16px;
  color: #a4a3b1;
}

.motif-form-field {
  margin-bottom: 0;
}
