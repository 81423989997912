.add-forecast {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.add-forecast-eng-header {
  display: flex;
  column-gap: 2rem;
  padding: 2rem;
  justify-content: space-between;
  border-bottom: 1px solid #3f3f4c;
}

.add-forecast-eng-header-title {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
}

.add-forecast-eng-header-stepper {
  width: 60%;
}

.add-forecast-content {
  display: flex;
  padding: 2rem;
  flex: 1;
  flex-direction: column;
  height: auto;
  overflow-y: auto;
}

.add-forecast-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
}

.add-forecast-header-main {
  font-size: 18px;
  font-weight: 400;
  color: #dedee2;
}

.add-forecast-header-button {
  display: flex;
  column-gap: 2rem;

  & button {
    font-size: 16px !important;
  }
}

.add-forecast-body-upload-year {
  display: flex;
  column-gap: 2rem;
  align-items: center;

  & .motif-input-component {
    width: 17rem;
  }

  & span {
    width: 12rem;
    color: #a4a3b1;
  }
}

.add-forecast-body-upload-year-label-l {
  flex: 1;
  color: #dedee2 !important;
  font-size: 16px;
}

.add-forecast-body-table {
  margin-top: 2rem;

  .motif-table .ag-cell {
    & svg {
      color: #85b9fd;

      & path {
        fill: unset;
        stroke: #85b9fd;
      }
    }
  }

  .motif-chip
    .motif-chip-button:not(
      .motif-chip-monochrome-light,
      .motif-chip-monochrome-dark,
      .motif-chip-success,
      .motif-chip-warning,
      .motif-chip-error
    ) {
    background: #2e2e38;
    border: 0;
    font-size: 12px;
    display: inline-block;
    max-width: 20rem;
    overflow: hidden !important;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.add-forecast-table-pending-validation {
  .ag-cell-value,
  .motif-chip-button {
    color: #ff4136 !important;
  }
}

.add-forecast-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2rem 0 2rem;
  border-top: 1px solid #3f3f4c;
}

.add-forecast-footer-nav-buttons {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  button {
    width: 4rem;
    align-self: flex-end;
  }
}
