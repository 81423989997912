.simulate-waterflow-graph-content {
  .highcharts-legend-item {
    & text {
      fill: #dedee2 !important;
      font-size: 1em !important;
      font-weight: 100;
    }
  }
  .highcharts-legend-box {
    fill: transparent;
  }
  .highcharts-xaxis-labels {
    & text {
      fill: #afaeba !important;
      font-weight: 400;
      font-family: var(--primary-font);
    }
  }
  .highcharts-xaxis {
    & text {
      font-weight: 400;
      font-family: var(--primary-font);
      font-size: 12px !important;
      fill: #afaeba !important;
    }
  }
  .highcharts-credits {
    display: none;
  }
  .highcharts-figure,
  .highcharts-data-table table {
    min-width: 320px;
    max-width: 700px;
    margin: 1em auto;
  }

  .highcharts-data-table table {
    font-family: Verdana, sans-serif;
    border-collapse: collapse;
    border: 1px solid #ebebeb;
    margin: 10px auto;
    text-align: center;
    width: 100%;
    max-width: 500px;
  }

  .highcharts-data-table caption {
    padding: 1em 0;
    font-size: 1.2em;
    color: #555;
  }

  .highcharts-data-table th {
    font-weight: 600;
    padding: 0.5em;
  }

  .highcharts-data-table td,
  .highcharts-data-table th,
  .highcharts-data-table caption {
    padding: 0.5em;
  }

  .highcharts-data-table thead tr,
  .highcharts-data-table tr:nth-child(even) {
    background: #f8f8f8;
  }

  .highcharts-data-table tr:hover {
    background: #f1f7ff;
  }
  .highcharts-exporting-group{
    display: none !important;
  }
  .highcharts-text-outline{
    stroke-width: 0 !important;
  }

  .highcharts-data-label{
  text {
  color: #fff !important;
  font-size: 0.7em !important;
  font-weight: normal !important;
  fill: #fff !important;
  }}

  .circle-list {
    list-style-type: none; /* Remove default bullets */
    padding: 0; /* Remove default padding */
    margin: 0; /* Remove default margin */
  }
  
  .circle-list li {
    position: relative;
    padding-left: 20px;
    display: inline;
    margin-right: 15px;
    color: #dedee2;
    font-size: 1em;
    font-weight: 100;
  }
  
  .circle-list li::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 14px;
    height: 14px;
    border-radius: 50%; /* Make it a circle */
  }

  .circle-list .Increase::before {
  background-color: #229B9A; /* Circle color */
  }
  .circle-list .Decrease::before {
  background-color: #5F5F72; /* Circle color */
    }
  .circle-list .Total::before {
  background-color: #B26100; /* Circle color */
  }
  .custom-legend{
    text-align: center;
    margin-top: 10px;
  }
}
