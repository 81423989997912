.decarb-tab-navigation {
  column-gap: 2px;
  border: 1px solid #33333e !important;
  border-radius: 2px;

  & .motif-tab-button {
    font-size: 14px;
    font-weight: 400 !important;
    flex: 1;
    background-color: #1a1a24;
    margin: 5px;
    padding: 8px 12px !important;
  }

  & .motif-active {
    border-radius: 2px;
    border-color: #4e4e5d;
    border-width: 1px;
    background-color: #2e2e38;
  }

  & .inactive-tab:not(:disabled) {
    background-color: inherit;
    border-width: 0px;
    outline: 1px #4e4e5d;
    --tab-navigation--color: #a4a3b1 !important;
  }

  & .inactive-tab:not(:disabled):hover {
    background-color: inherit;
    border-width: 0px;
    outline: 1px solid #4e4e5d;
  }

  // & .motif-tab-button:hover:active {
  //   border: 0 !important;
  // }

  // & .motif-tab-button:focus-visible:active {
  //   border: 0 !important;
  //   outline: 0 !important;
  // }

  & .motif-tab-button:focus-visible {
    border: 0 !important;
    outline: 0 !important;
  }

  & .motif-tab-button:hover {
    background-color: inherit !important;
    color: inherit !important;
    box-shadow: none !important;
  }

  // & .motif-tab-button:hover {
  //   border-width: 0 !important;
  // }
}
