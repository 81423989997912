@use "@ey-xd/motif-core/src/styles/themes/utils/rem.function" as Rem;

.emissions {
  display: flex;
  flex-direction: column;
  background-color: #23232f;
  padding: 2rem;
  row-gap: 2rem;
  overflow: auto;
}

.emission-header {
  border-bottom: 1px solid #3f3f4c;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
}
.emission-header-content {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  .emission-header-content-title {
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
    align-items: center;
    span {
      color: #dedee2;
    }
  }
  .emission-header-content-body {
    display: flex;
    flex-direction: row;
    column-gap: 2rem;
    flex: 1;
    align-items: flex-end;

    & .decarb-loading-indicator-embed {
      width: fit-content;
    }

    .emission-header-content-body-dropdown {
      display: flex;
      flex-direction: column;
      row-gap: 0.5rem;
      flex: 1;
      width: 12rem;
    }
    .motif-button {
      display: flex;
      align-self: flex-end;
    }
    .custom1 {
      background-color: #2e2e38;
      border: none;
      border-radius: 4px;
      width: fit-content;
    }
  }
}
.custom1 {
  background-color: #2e2e38;
  border: none;
  border-radius: 4px;
  width: fit-content;
}
.custom2 {
  border: 1px solid #4e4e5d;
  border-radius: 4px;
  font-weight: 400;
}
.custom3 {
  background-color: transparent;
  border: 0;
  font-weight: 400;
}
.emission-header-main {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  .emission-header-title {
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
    align-items: center;
    span {
      color: #dedee2;
      font-family: EYInterstate;
      font-size: 18px;
      font-weight: 400;
      line-height: 28px;
      text-align: left;
    }
  }
}

.emissions-body {
  display: flex;
  flex: 1;
  .emissions-table {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    flex: 1;
    .emissions-table-filter-section {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex: 1;

      > button {
        border: none;
        width: fit-content;
        svg {
          padding-right: 0.35rem;
        }
      }
    }
    .emissions-table-filter-section-custom {
      display: flex;
      flex-direction: row;
      column-gap: 2rem;
      .motif-select {
        width: 20rem;
      }
    }
    .emissions-table-table-section {
      .motif-input-component .motif-input-clear-button {
        display: none;
      }
    }
  }
}
.emission-filter-modal {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  flex: 1;
}
.motif-modal-body {
  padding-top: 0;
}
.emission-filter-modal-content {
  display: flex;
  flex-direction: row;
  column-gap: 3rem;
  align-items: flex-start;
  justify-items: center;
  flex: 1;

  // .motif-select {
  //   width: 15rem;
  // }
  .emission-filter-modal-content-main {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    flex: 1;
    .emission-filter-modal-label {
      color: #a4a3b1;
      font-weight: 400;
      line-height: 30px;
      font-size: 16px;
    }
  }
  .emission-filter-modal-content-dropdowns {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    flex: 1;
    max-width: 20rem;
    .emission-filter-modal-content-dropdowns-label {
      padding-bottom: 0.5rem;
      color: #dedee2;
      font-size: 14px;
    }
  }
}
.emission-filter-modal-header {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  align-items: center;
}
.emission-filter-modal-footer {
  display: flex;
  flex-direction: row;
  column-gap: 2rem;
  justify-content: flex-end;
  flex: 1;
  margin: 1rem 0rem 0rem 0rem;

  & .decarb-loading-indicator-embed {
    width: fit-content;
  }
}
.emissions-chart-section {
  display: flex;
  flex-direction: row;
  column-gap: 2rem;
  flex: 1;
  .emissions-simulate-section {
    width: 40%;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    border: 1px solid #33333e;
    padding: 2rem;
    .emissions-simulate-title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      span {
        color: #a4a3b1;
        font-family: EYInterstate;
        font-size: 16px;
        font-weight: 300;
        line-height: 28px;
        text-align: left;
      }
    }
    .emissions-simulate-content {
      display: flex;
      flex-direction: column;
      row-gap: 2rem;
      padding: 2rem 0;
      .emissions-simultae-content-row {
        display: flex;
        flex-direction: row;
        column-gap: 1rem;
        flex: 1;
        .emissions-simulate-content-row-dropdown {
          display: flex;
          flex-direction: column;
          row-gap: 0.5rem;
          flex: 1;

          .emissions-simulate-unit-validation-error {
            color: #d07200;
            display: flex;
            align-self: center;
            svg {
              margin-right: 0.5rem;
              align-self: center;
            }
          }
          span {
            display: flex;
            flex-direction: row;
            column-gap: 0.3rem;
            align-content: center;
          }
        }
        .custom-class {
          display: flex;
          flex-direction: column;
          width: 46%;
        }
      }
    }
    .emissions-simulate-footer {
      display: flex;
      flex-direction: row;
      column-gap: 2rem;

      .decarb-button {
        width: 100%;
      }
    }
  }

  .emissions-graph-section {
    width: 60%;
    height: auto;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    border: 1px solid #33333e;
    padding: 1rem;
    .emissions-graph-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      span {
        color: #a4a3b1;
        font-family: EYInterstate;
        font-size: 16px;
        font-weight: 300;
        text-align: left;
      }
      .emissions-graph-title-log {
        //styleName: Button/md;
        font-family: EYInterstate;
        font-size: 16px;
        font-weight: 400;
        text-align: left;
        color: #85b9fd;
      }
      button {
        text-underline-offset: none;
      }
    }
    .motif-modal {
      max-width: 95%;
    }
    .emissions-graph-content {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      padding: 2rem 0;
      .highcharts-credits {
        display: none !important;
      }
      .highcharts-text-outline {
        stroke-width: 0 !important;
      }
      .highcharts-data-label text {
        font-size: 0.8em !important;
        font-weight: unset !important;
        fill: #fff !important;
      }
      tspan {
        stroke: unset;
        stroke-width: unset;
      }
      .emissions-graph-content-noData {
        text-align: center;
      }
      .highcharts-exporting-group {
        display: none;
      }
    }
  }
}
.emissions-graph-section {
  .highcharts-background {
    fill: none;
  }
}
.emissions-change-log-header {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  width: 100%;
  .emissions-change-log-header-title {
    font-family: EYInterstate;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: #dedee2;
  }
  .emissions-change-log-header-description {
    font-family: EYInterstate;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    text-align: left;
    color: #8a8a9b;
  }
}

.emissions-change-log-modal {
  max-width: 95%;
  max-height: 99%;
  .motif-modal-body {
    max-height: 90% !important;
    padding: 1rem;
    overflow: auto;
    border: none;
  }
}

.emissions-change-log {
  display: flex;
  flex: 1;

  .motif-modal {
    max-width: 95%;
  }
}
.change-log-table-pending-validation {
  opacity: 0.5;
  pointer-events: none;
  .ag-cell-value {
    color: #ff4136 !important;
  }
}
.emissions-change-log-content {
  width: 100%;
  max-height: 95%;
  .motif-table {
    max-height: 90%;
    min-height: 0;
    overflow: auto;
    .ag-body {
      max-height: 17rem;
      overflow-y: auto;
    }
  }
  .ag-cell-value {
    text-overflow: ellipsis;
    word-wrap: normal;
    padding: 0.5rem;
  }
}
.emissions-change-log-footer {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  justify-content: flex-end;
}
.emissions-table {
  .motif-modal {
    max-width: 90%;
  }
}
.emissions-reset-filters-footer {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  justify-content: flex-end;
  padding-top: 2rem;
}

.emission-motif-modal {
  max-width: Rem.rem(1024);
}
.extend-row {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  width: 100%;
  .extend-row-input {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
  }
}
.extend-modal-buttons {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  margin-top: 2rem;
  justify-content: flex-end;
}
.extend-modal-error-message {
  color: #d07200;
  font-family: EYInterstate;
  font-weight: bold;
}

.rmsc .clear-selected-button {
  display: none;
}
