.delete-forecast-icon {
  background-color: transparent;
  svg {
    stroke: #85b9fd !important;
  }
}
.delete-forecast-icon-inactive {
  svg {
    stroke: #85b9fd !important;
  }
}

.add-target-table-custom-edit {
  display: flex;
  column-gap: 1.5rem;
  justify-content: center;
  width: 100%;
}
