.add-target-specification {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.add-target-specification-header {
  display: flex;
  column-gap: 2rem;
  padding: 2rem;
  justify-content: space-between;
  border-bottom: 1px solid #3f3f4c;
}

.add-target-specification-header-title {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
}

.add-target-specification-header-stepper {
  width: 60%;
}

.add-target-specification-content {
  display: flex;
  padding: 2rem;
  flex: 1;
  flex-direction: column;
  overflow: auto;
}

.add-target-specification-content-header {
  display: flex;
  flex-direction: column;
}

.add-target-specification-content-header-main {
  font-size: 16px;
  color: #a4a3b1;
}

.add-target-specification-content-body {
  margin-top: 2rem;
}

.add-target-specification-content-body-row {
  display: flex;
  column-gap: 2rem;
  padding-bottom: 1rem;
}

.add-target-specification-content-body-name-title {
  width: 12rem;
  color: #a4a3b1;
  font-weight: 400;
  align-self: center;
  display: flex;
  flex-direction: row;
  column-gap: 0.2rem;
}

.add-target-specification-content-body-alternative-output {
  color: #a4a3b1;
  align-self: center;
  font-weight: 600;
}
.add-target-specification-content-body-alternative-output-description {
  color: #a4a3b1;
  align-self: center;
  margin: 15px 0px;
}
.decarb-target-checkbox {
  display: unset;
  margin: 0px 15px;
  position: absolute;
}

.add-target-specification-content-body-desc-title {
  width: 12rem;
  color: #a4a3b1;
  font-weight: 400;
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  column-gap: 0.3rem;
}

.add-target-specification-content-body-button {
  border: none;
  flex: 1;
  width: 100%;

  & svg {
    color: #fff;

    & path {
      stroke: #fff;
    }
  }

  p {
    display: inline-block;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-left: 1rem;
    padding: 0.1rem 0.5rem;
    color: #a4a3b1;
    background-color: #3f3f4c;
    font-size: 12px;
  }
}

.add-target-specification-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2rem 0 2rem;

  border-top: 1px solid #3f3f4c;
}
.add-target-intensity-table {
  position: relative !important;
}
