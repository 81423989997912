.decarb-search-input {
  display: flex;

  & .motif-input-component {
    width: 100%;
  }

  & input {
    font-size: 16px !important;
    color: #a4a3b1;
    border-radius: 4px !important;
    border: 1px solid #4e4e5d !important;
    width: 100%;
  }

  & svg {
    color: #a4a3b1;

    & path {
      stroke: #a4a3b1;
      fill: none;
    }
  }

  & .motif-search-input-icon {
    position: relative;
    top: 1px;
    right: 30px;
  }
}

.decarb-input::-webkit-outer-spin-button,
.decarb-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: none;
}
