.view-engagement {
  padding: 2rem;
  height: 100%;

  .decarb-loading-indicator-embed {
    height: calc(100% - 54px);
  }
}

.view-engagement-header {
  display: flex;
  justify-content: space-between;
  flex: 1;
}

.view-engagement-header-title {
  font-size: 28px;
  font-weight: 400;
  color: #dedee2;
}

.view-engagement-header-h {
  display: flex;
  flex-direction: column;
}

.view-engagement-table {
  margin-top: 2rem;
  padding-bottom: 2rem;

  .motif-tooltip-wrapper {
    display: flex;
  }

  & .ag-row {
    opacity: 0.99;
  }
}

.view-engagement-link {
  font-weight: 800;
  font-size: 14px;
  text-decoration: underline;
  color: #85b9fd;

  &:hover {
    cursor: pointer;
  }
}

.view-engagement-close-project {
  font-size: 14px;
  display: flex;

  & svg {
    color: #85b9fd;

    & path {
      fill: unset;
      stroke: #85b9fd;
    }
  }
}
.engagement-dashboard-fullscreen {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.motif-card-head {
  display: flex;
  flex-direction: column;
  font-family: EYInterstate;
  padding: 2rem;
  background-color: #2e2e38;
}
.dashboard-engagement-name {
  display: flex;
  flex: 1;
  width: 100%;
  span {
    font-size: 16px;
    font-family: EYInterstate;
    color: white;
    padding: 0.5rem;
  }
}
.dashboard-cards {
  display: flex;
  flex: 1;
  flex-direction: row;
}
.dashboards-card-home {
  margin: 1rem;
  height: fit-content;
  .motif-card-footer {
    justify-content: center;
  }
  border-radius: 1rem;
  background-color: #1a1a24;
}
.dashboards-card-home-disabled {
  opacity: 0.3;
  pointer-events: none;
}
.dashboards-card-home-header {
  align-items: center;
  span {
    font-size: 18px;
    font-family: EYInterstate;
    color: white;
    padding: 1rem;
    font-weight: bold;
  }
}

.dashboard-cards-description-body-label {
  display: flex;
  margin-bottom: 2rem;
  justify-content: center;
  height: 5rem;
  font-size: 14px;
  text-align: center;
  color: white;
  font-family: EYInterstate;
}
.dashboard-cards-description-body {
  img {
    height: 10rem;
    margin-left: 1rem;
  }
}
.view-engagement-link-inactive {
  text-decoration: none !important;
  cursor: unset !important;
  pointer-events: none;
  & path {
    stroke: #737387 !important;
  }
}

.view-engagement-closed-project {
  opacity: 0.5 !important;
}

.view-engagement-stepper {
  & .motif-progress-indicator-step-status-title {
    background-color: var(--ag-background-color);
  }

  & .motif-progress-indicator-step-status-situation {
    background-color: var(--ag-background-color) !important;
  }
}

// .motif-card-head .motif-card-orientation-vertical {
//   width: 100% !important;
//   height: 18rem !important;
// }

.chip-title {
  padding-left: 10px;
  padding-top: 10px;
}

.card-header .motif-chip .motif-chip-button.motif-chip-success {
  color: #f8f8fc !important;
  background-color: rgb(38, 160, 75);
}

.card-header .motif-chip .motif-chip-button.motif-chip-warning {
  color: #f8f8fc !important;
}

.body-content {
  padding-left: 10px;
  font-weight: 400px;
  font-size: 18px;
  line-height: 24px;
}

.motif-card-head {
  display: flex;
  flex: 1;
  margin-right: 7%;
  margin-bottom: 15px;
  width: 100%;
}

.transparent-button {
  background: transparent;
  border: none;
  color: #dedee2;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  font-size: 16px;
  span {
    margin-left: 2px;
    height: 1.4rem;
  }
  svg path {
    stroke: #dedee2;
  }
}

.card-header .motif-card-footer :only-child {
  margin: unset !important;
  justify-content: flex-end !important;
  margin-left: auto !important;
  padding: 3px !important;
}
// .motif-card-body {
//   overflow-y: hidden !important;
// }
