.new-project {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.new-project-header {
  display: flex;
  column-gap: 2rem;
  padding: 2rem;
  justify-content: space-between;
  border-bottom: 1px solid #3f3f4c;
  border-top: 1px solid #3f3f4c;
}

.new-project-header-title {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
}

.new-project-header-stepper {
  width: 60%;
}

.new-project-content {
  display: flex;
  margin: 2rem 2rem 0 2rem;
  flex: 1;
  flex-direction: column;
  overflow: auto;
}

.new-project-content-header {
  display: flex;
  flex-direction: column;
}

.new-project-content-header-main {
  display: flex;
  align-items: center;
  column-gap: 1rem;

  & :not(:first-child) {
    color: #8a8a9b;
    font-size: 16px;
    font-weight: 400;
  }
}

.new-project-content-header-main-analyse {
  font-size: 16px;
  font-weight: 400;
}

.new-project-content-header-desc {
  font-size: 14px;
  font-weight: 400;
  color: #c4c4cd;
  font-style: italic;
  margin-top: 1rem;
}

.new-project-content-body {
  margin-top: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.new-project-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2rem;
  border-top: 1px solid #3f3f4c;
  background-color: #23232f;
}

.new-project-footer-nav-buttons {
  display: flex;
  column-gap: 1rem;
}
