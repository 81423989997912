.decarb-modal {
  overflow-y: clip;

  & .motif-modal-header {
    padding-bottom: 0rem;
    border-bottom: 0px;
    min-height: fit-content;

    h3 {
      color: #dedee2;
    }

    & .motif-modal-headline {
      padding: 0;
      overflow-y: auto;
    }
  }

  & .motif-modal-body {
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: 0;
  }

  & .motif-modal-footer {
    border-style: none;
    & button + button {
      margin-top: 0;
    }
  }
/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #a8a8a8; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888; 
}
}
